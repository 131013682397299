<template>
  <div class="auth container">
    <template v-if="!isSessionPending">
      <div v-if="!isSessionPending && hasSession" class="font font_title-xl font_grey">
        Вы успешно авторизованы
      </div>
    </template>
    <div v-else class="font font_title-xl font_grey">
      Идет проверка авторизации
      <LoaderDots />
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import LoaderDots from '~/components/elements/LoaderDots';

export default {
  name: 'AuthPage',
  components: {
    LoaderDots,
  },
  computed: {
    ...mapState('auth', ['hasSession', 'isSessionPending', 'isOpenedAuthModal']),
  },
  watch: {
    async hasSession(value) {
      if (value) {
        this.setIsOpenedAuthModal(false)
        const lastPage = await this.getLastPage()
        await this.removeLastPage()

        this.$router.push({ path: lastPage || '/' })
      }
    },

    isSessionPending(value) {
      if (!value && !this.hasSession) {
        this.setIsOpenedAuthModal(true)
      }
    },

    isOpenedAuthModal(value) {
      if (!value && !this.hasSession) {
        this.$router.push({ path: '/' })
      }
    },
  },
  beforeDestroy() {
    this.removeLastPage()
  },
  methods: {
    ...mapActions({
      getLastPage: 'auth/getLastPage',
      removeLastPage: 'auth/removeLastPage',
    }),
    ...mapMutations('auth', {
      setIsOpenedAuthModal: 'SET_IS_OPENED_AUTH_MODAL',
    }),
  },

}
</script>
